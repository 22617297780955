<template>
  <div
    class="card project-card"
    style="background-color: #393e46; color: white; margin: 20px"
  >
    <div class="card-content">
      <div class="content">
        <div class="columns is-multiline is-centered">
          <div class="column is-half is-centered is-one-quarter column-logo">
            
              <div class="image is-rounded logo-image">
                <img
                  class="main-image "
                  :src="require(`@/assets/${image}`)"
                  alt="Placeholder image"
                />
              </div>
          </div>
          <div class="column is-three-quaters">
            <p class="project-description">{{ description }}</p>
            <br />
            <p class="project-language">
              <span class="tag is-warning is-light">{{ languages }}</span>
            </p>
            <h5>
              <a class="project-links" :href="`${link}`">Learn more</a
              ><a class="project-links" :href="`${link}`"
                ><font-awesome-icon
                  class="github-icon"
                  title="Github"
                  :icon="['fab', 'github']"
                />Check it on Github!</a
              >
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
import "bulma/css/bulma.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas);
library.add(fab);
export default {
  name: "ProjectCard",
  props: {
    name: String,
    image: String,
    description: String,
    link: String,
    languages: String,
  },
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
};
</script>

<style scoped>
.logo-image {
  width: 156px;
  height: auto;
  margin: 5%;
}

.project-language {
  text-align: left !important;
  font-style: italic;
  font-weight: 1000;
}

.project-links {
  margin-right: 40px;
}

.main-image {
  border-radius: 25%;
}
.project-description {
  text-align: left;
}

.github-icon {
  font-size: 20px !important;
  color: white;
  margin-right: 5px;
}

.project-card {
  box-shadow: rgb(20, 20, 20) 10px 10px 20px 5px;
}

.column-logo {
}

@media screen and (max-width: 769px) {
  .project-card {
    box-shadow: rgb(20, 20, 20) 10px 10px 20px 5px;
    justify-items: center;
    text-align: center !important;
  }

  .project-description {
    text-align: center;
    flex: initial;
  }

  .column-logo {
    align-items: center;
    justify-content: center;
    
    flex: initial;
    text-align: -webkit-center;
  }
  .logo-image {
    width: 200px;
    height: auto;
    vertical-align: middle;
  display: table-cell;
  text-align: center;
  }
}
</style>