<template>

<Nav/>

  <div class="main">
      <div class="divider">404 - This page does not exists</div>
      <p>Take me back to <router-link to="/">safety</router-link></p>
  </div> 
</template>

<script>

import Nav from "../components/Nav.vue";

export default {
  components: {
    Nav,
  },
};
</script>

<style scoped>

.divider {
  font-size: 1.5rem;
  color: #ffaa04;
}

.main {
  padding-left: 15%;
  padding-right: 15%;
}

@media screen and (max-width: 1000px) {
  .main {
    /*
  max-width: 50%;
  margin-left: 25%;*/
    padding-left: 3%;
    padding-right: 3%;
  }
}
</style>