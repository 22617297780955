<template>
  <Nav />
  <div class="main">
    <div class="divider">About</div>
    <div class="container">
      <div class="columns">
        <div class="column is-one-third">
          <div class="card">
            <div class="card-image">
              <figure class="image profile">
                <img src="../assets/profile.jpeg" alt="Placeholder image" />
              </figure>
            </div>
            <div class="card-content">
              <div class="media">
                <div class="media-content">
                  <p class="title is-4">Imanol Fotia</p>
                  <a
                    href="https://twitter.com/ImanolFotia"
                    class="subtitle is-6"
                    >@ImanolFotia</a
                  >

                  <p>
                    <font-awesome-icon
                      size="1x"
                      title="Youtube"
                      :icon="['fas', 'envelope']"
                    />
                    contact me:
                    <a :href="`mailto:${email}`">{{ email }}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-two-thirds">
          <p class="box" style="text-align: start">
            Hello there! My name is Imanol, and I am Computer Engineer and
            graphics/game programmer. I am currently developing my first game,
            using my home made engine.
          </p>
          <p class="box" style="text-align: start">
            Even though I have knowledge of various programming languages, the
            real deal for me is C++, specially C++11, so expect to see a lot of
            it around here. By the way, you can talk to me in both English and
            Spanish(I have knowledge of Japanese, but I’m not there yet).
          </p>
          <p class="box" style="text-align: start">
            I am a big fan of Metal Gear Solid, The Lord Of The Rings and Heavy
            Metal(yes, in that order).
          </p>

          <h4>
            If you learned something from my blog, a tip is always welcomed
          </h4>

          <code class="box wallet">
            <font-awesome-icon
              size="2x"
              title="Bitcoin (BTC)"
              :icon="['fab', 'bitcoin']"
              class="coin-logo"
              style="min-width: 30px; color: #f2a900; margin-bottom: -5px"
            />
            <a
              href="https://mempool.space/address/bc1pdgshj5jcpdqpz3r4yj2sq76yj0m8lk6tmxcs22h70mh3xv6qduwsgjw7ch"
              target="_blank"
              style="
                text-decoration: underline;
                color: #f2a900;
                padding-left: 10px;
                font-size: 12px;
              "
              >bc1pdgshj5jcpdqpz3r4yj2sq76yj0m8lk6tmxcs22h70mh3xv6qduwsgjw7ch
              (Taproot)</a
            >
          </code>

          <code class="box wallet">
            <font-awesome-icon
              size="2x"
              title="Ethereum (ETC)"
              :icon="['fab', 'ethereum']"
              class="coin-logo"
              style="min-width: 30px; color: #ecf0f1; margin-bottom: -5px"
            />
            <a
              href="https://etherscan.io/address/0xe06421D03A06A99735acc60fB9A90A41afeb889B"
              target="_blank"
              style="
                text-decoration: underline;
                color: #ecf0f1;
                overflow: wrap;
                padding-left: 10px;
                font-size: 12px;
              "
              >0xe06421D03A06A99735acc60fB9A90A41afeb889B (ERC-20 & BEP20)</a
            >
          </code>

          <code class="box wallet">
            <font-awesome-icon
              size="2x"
              title="Monero (XMR)"
              :icon="['fab', 'monero']"
              class="coin-logo"
              style="min-width: 30px; color: #ff6600; margin-bottom: -5px"
            />
            <span style="overflow: wrap; padding-left: 10px; font-size: 12px"
              >425rJsArWhZRarQjSYxPVdAvKkXXYyDJmNRwNbTEbH8RH6wEUGYzyHPDQGj3d2PotJ4auW4ubCC6U6Wo6Ekv1MKcCx6rrcT</span
            >
          </code>

          <code class="box wallet">
            <font-awesome-icon
              size="2x"
              title="Paypal"
              :icon="['fab', 'paypal']"
              class="coin-logo"
              style="min-width: 30px; color: #009cde; margin-bottom: -5px"
            />
            <a
              href="https://paypal.me/ImanolFotia"
              target="_blank"
              style="
                text-decoration: underline;
                color: #009cde;
                padding-left: 10px;
                font-size: 12px;
              "
              >paypal.me/ImanolFotia</a
            >
          </code>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas);
library.add(fab);

export default {
  props: {
    email: String,
    createdBy: String,
    year: String,
  },
  components: {
    Nav,
    "font-awesome-icon": FontAwesomeIcon,
  },
};
</script>

<style scoped>
h1 {
  margin: 40px 0 0 !important;
  font-size: 2em;
  font-weight: bold;
  padding-bottom: 25px;
}
h2 {
  margin: 40px 0 0 !important;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 25px;
}

h3 {
  margin: 40px 0 0 !important;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 25px;
}

h4 {
  margin: 40px 0 0 !important;
  font-size: 1.25em;
  font-weight: bold;
  padding-bottom: 25px;
}
.main {
  padding-left: 15%;
  padding-right: 15%;
}

.coin-logo {
}

.wallet {
  text-align: start;
  overflow: auto;
}

@media screen and (max-width: 574px) {
  .coin-logo {
    width: 100%;
    padding-bottom: 10px;
  }

  .wallet {
    text-align: center;
    text-justify: kashida;
  }
}

@media screen and (max-width: 1000px) {
  .main {
    /*
  max-width: 50%;
  margin-left: 25%;*/
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media screen and (max-width: 1510px) {
  .main {
    padding-left: 5%;
    padding-right: 5%;
  }
}

.profile {
  width: 100%;
}

.box {
  color: white;
  background-color: #393e46;
  padding: 10px;
  margin: 5px;
}

.divider {
  font-size: 1.5rem;
  color: #ffaa04;
}
</style>