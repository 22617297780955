<template>
  <Nav />
  <div class="divider">Markdown Editor</div>

  <div id="editor">
    <nav class="level">
      <div class="level-right"></div>
      <div class="level-right">
        <p class="level-item right">
          <button class="button is-success">
            <font-awesome-icon
              size="1x"
              title="Youtube"
              :icon="['fas', 'download']"
              style="margin-right: 7px;"
            /> 
            Download PDF
          </button>
        </p>
      </div>
    </nav>
    <textarea :value="input" @input="update"></textarea>
    <div v-html="compiledMarkdown"></div>
  </div>
</template>

<script>
import "bulma";
import marked from "marked";
import _ from "lodash";
import Nav from "../../components/Nav.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas);
library.add(fab);

export default {
  name: "Editor",
  data() {
    return {
      input: "# hello",
    };
  },
  components: {
    Nav,
    "font-awesome-icon": FontAwesomeIcon,
  },
  computed: {
    compiledMarkdown() {
      return marked(this.input);
    },
  },
  methods: {
    update: _.debounce(function (e) {
      this.input = e.target.value;
    }, 300),
  },
};
</script>

<style scoped>

.level-item.right {
    justify-content: right;
}

h1 {
  font-size: 36px !important;
}

h2 {
  font-size: 30px !important;
}

h3 {
  font-size: 24px !important;
}

h4 {
  font-size: 18px !important;
}

h5 {
  font-size: 14px !important;
}

h6 {
  font-size: 12px !important;
}
ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
ul ul {
  list-style-type: circle;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

ul ul ul {
  list-style-type: square;
  margin-block-start: 0px;
  margin-block-end: 0px;
}

ul ul ul ul {
  list-style-type: disclosure-closed;
  margin-block-start: 0px;
  margin-block-end: 0px;
}
ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}
blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}
blockquote:before,
blockquote:after {
  content: "";
}

#editor {
  margin: 0;
  height: 100%;
  font-family: "Helvetica Neue", Arial, sans-serif;
  color: #eeeeee;
  background-color: #222831;
  text-align: start;
  width: 100%;
  padding-left: 5%;
}

textarea,
#editor div {
  display: inline-block;
  width: 49%;
  height: 70%;
  vertical-align: top;
  box-sizing: border-box;
  padding: 0 20px;
}

html {
  margin: 0;
  height: 100%;
}

#main {
  height: 100%;
  min-height: 100%;
}

textarea {
  border: none;
  border-right: 1px solid #ccc;
  resize: none;
  outline: none;
  background-color: #f6f6f6;
  font-size: 14px;
  font-family: "Monaco", courier, monospace;
  padding: 20px;
}

code {
  color: #f66;
}
.divider {
  font-size: 1.5rem;
}
.box {
  margin-bottom: 1%;
}
</style>