import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'

//Vue.config.productionTip = false

import Home from './components/Main.vue'
import About from './views/About.vue'
import Demos from './views/Demos.vue'
import Projects from './views/Projects.vue'
import MdEditor from './views/projects/MdEditor.vue'
import Blog from './views/Blog.vue'
import Downloads from './views/Downloads.vue'
import NotFound from './views/NotFound.vue'

const routes = [
    { path: '/', component: Home, props: {msg: "Imanol Fotia"} },
    { path: '/about', component: About , props: {email:"imanolfotia@gmail.com", year:"2021", createdBy:"Imanol Fotia" }},
    { path: '/demos', component: Demos },
    { path: '/projects', component: Projects },
    { path: '/projects/MdEditor', component: MdEditor },
    { path: '/blog', component: Blog },
    { path: '/downloads', component: Downloads },
    { path: '/404', component: NotFound},
    { path: '/:catchAll(.*)', redirect: '/404'},
    { path: '/blog/:catchAll(.*)', redirect: '/blog'}
]

const router = createRouter({
    history: createWebHistory(),
    routes, // short for `routes: routes`
    scrollBehavior() {
        return { top: 0 }
      }
})

// 5. Create and mount the root instance.
const app = createApp(App)
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)


app.mount('#app')

