<template>
  <Nav />
  <div class="main">
      <div class="divider">Demos</div>

    <p>
      Here you will find live coded GLSL shaders that I like to write in my
      spare time. All demos are powered by Shadertoy.
    </p>

    <h4>Metaballs</h4>
    <p>
      This demo uses signed distance fields with raymarching to render the
      metaballs, it also implements Cook-Torrance specular model and
      reflections/refractions.
    </p>
    <iframe
      width="640"
      height="360"
      frameborder="0"
      src="https://www.shadertoy.com/embed/lsScz1?gui=true&t=10&paused=true&muted=false"
      allowfullscreen
    ></iframe>

    <h4>Cook-Torrance + Oren-Nayar</h4>
    <p>
      This one also uses signed distance fields and is implementing Oren-Nayar
      for the diffuse light contribution along with Cook-Torrance for the
      specular component.
    </p>
    <iframe
      width="640"
      height="360"
      frameborder="0"
      src="https://www.shadertoy.com/embed/MsSczh?gui=true&t=10&paused=true&muted=false"
      allowfullscreen
    ></iframe>

    <h4>Procedural Fire</h4>
    <p>
      Procedural fire using Fractal brownian Motion and raymarching. Plus a LCD
      postprocess effect.
    </p>
    <iframe
      width="640"
      height="360"
      frameborder="0"
      src="https://www.shadertoy.com/embed/XllyW8?gui=true&t=10&paused=true&muted=false"
      allowfullscreen
    ></iframe>

    <h4>Mandelbrot Fractal</h4>
    <p>Plain and simple Mandelbrot set rendering.</p>
    <iframe
      width="640"
      height="360"
      frameborder="0"
      src="https://www.shadertoy.com/embed/MsjyzG?gui=true&t=10&paused=true&muted=false"
      allowfullscreen
    ></iframe>

    <h4>Wasted land</h4>
    <p>
      An infinite wasted land, once again, raymarching primitives looking good!
    </p>
    <iframe
      width="640"
      height="360"
      frameborder="0"
      src="https://www.shadertoy.com/embed/MsSyDy?gui=true&t=10&paused=true&muted=false"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";

export default {
  components: {
    Nav,
  },
};
</script>

<style scoped>
h1 {
  margin: 40px 0 0 !important;
  font-size: 2em;
  font-weight: bold;
  padding-bottom: 25px;
}
h2 {
  margin: 40px 0 0 !important;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 25px;
}

h3 {
  margin: 40px 0 0 !important;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 25px;
}

h4 {
  margin: 40px 0 0 !important;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 25px;
}
.main {
  padding-left: 15%;
  padding-right: 15%;
}

@media screen and (max-width: 1000px) {
  .main {
    /*
  max-width: 50%;
  margin-left: 25%;*/
    padding-left: 3%;
    padding-right: 3%;
  }
}


@media screen and (max-width: 1510px) {
  .main {
  padding-left: 5%;
  padding-right: 5%;

  }
}
.divider {
  font-size: 1.5rem;
  color: #ffaa04;
}
</style>