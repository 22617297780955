<template>
 <meta name="viewport" content="width=device-width, initial-scale=1">
  <router-view></router-view>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { faFontAwesome } from "@fortawesome/free-brands-svg-icons";

library.add(faUserSecret);
library.add(faFontAwesome);

export default {
  name: "App",
  components: {},
};
</script>

<style>
#app {
  font-family: Fira Sans, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #eeeeee;
  padding-top: 30px !important;
  background-color: #222831;
  height: 100%;
  padding: 0px;
  margin-bottom: 150px;
}

.home-div {
  height: 70px !important;
}

a {
  color: #00adb5;
}

html {
  background-color: #222831 !important;
}

body {
  /*393E46*/
  margin: 0px;
  padding: 0px;
  background-color: #222831;
  height: 100%;
}

#profile-img {
  border-radius: 50%;
  width: 300px;
}
</style>
