<template>
  <nav>
    <router-link
      v-for="route in $router.options.routes"
      :key="route.path"
      :to="route.path"
      >{{ route.name }}</router-link
    >
  </nav>
  <div class="container">
    <h1>{{ title }}</h1>
    <h5>Posted on: {{ date }} by Imanol Fotia</h5>
    <hr />
    <div id="md-content" class="content is-small">
      <span v-html="readHtml()"></span>
    </div>
  </div>

  <div id="disqus_container">
    <div id="disqus_thread"></div>
  </div>
  <noscript
    >Please enable JavaScript to view the
    <a href="https://disqus.com/?ref_noscript"
      >comments powered by Disqus.</a
    ></noscript
  >
</template>

<script scoped>
import { marked } from "marked";
import "bulma/css/bulma.css";
import { defineAsyncComponent } from "vue";


/**
 *  RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
 *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables    */

var disqus_config = function () {
  this.page.url = "localhost:8080/blog"; // Replace PAGE_URL with your page's canonical URL variable
  this.page.identifier = 0; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
};


const hljs = require("highlight.js");


export default {
  name: "BlogEntry",
  props: {
    title: String,
    category: String,
    filename: String,
    date: String,
    id: Number,
  },
  data() {
    return {
      input: "helloText",
      output: "# hello",
    };
  },

  mounted: function () {
    marked.setOptions({
      renderer: new marked.Renderer(),
      highlight: function (code, lang) {
        const cppHighlight = require("highlight.js/lib/languages/cpp");
        hljs.registerLanguage("javascript", cppHighlight);
        const language = hljs.getLanguage(lang) ? lang : "cpp";
        return hljs.highlight(code, { language }).value;
      },
      langPrefix: "cpp language-", // highlight.js css expects a top-level 'hljs' class.
      pedantic: false,
      gfm: true,
      breaks: false,
      sanitize: false,
      smartLists: true,
      smartypants: false,
      xhtml: false,
    });
  },

  methods: {
    async setHtml() {
      let text = await import("../markdowns/" + this.filename);
      return text.default;
    },

    readHtml() {
      var text;
      text = this.setHtml().then((a) => (this.output = a));
      return marked(this.output, {
        highlight: function (markdown) {
          return hljs.highlightAuto(markdown).value;
        },
      });
    },
  },
};
</script>

<style scoped>
@import "~highlight.js/styles/vs.css";

html {
  background-color: black;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  color: white !important;
  font-size: 2em !important;
}

pre code {
  background-color: black !important;
}

.content h1 {
  color: white !important;
}

#md-content {
  text-align: left !important;
}

h1 {
  font-size: 2em !important;
  text-align: left;
  color: white !important;
}

h2 {
  font-size: 1.5em;
  text-align: left;
}

h3 {
  font-size: 1.17em;
  text-align: left;
}

h4 {
  font-size: 1em;
  text-align: left;
}

h5 {
  font-size: 0.83em;
  text-align: left;
}

p {
  color: white;
  font-size: 16;
}
</style>