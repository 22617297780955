<template>
  <Nav />

  <div class="main">
    <div class="divider">Projects</div>
    <div class="container cards-container">
      <ProjectCard
        name="Epsilon Engine"
        image="Epsilon LOGO5.png"
        languages="C++ - OpenGL - Lua"
        description="Epsilon Engine© is a multi-platform, next-gen, on-development engine especifically designed to build high quality games and simulations. It uses OpenGL© 4.5 capabilities to deliver stunning graphics with great real time performance. It has been under development since 2015, and has constantly been catching up with the latest realtime, 3D techniques."
        link="https://github.com/ImanolFotia/Epsilon-Engine"
      />
      <ProjectCard
        name="Beacon"
        image="beacon.svg"
        languages="C++"
        description="Beacon is a multiparadigm C++ event management library with focus on fast and simple integration. Be wary, this is a work in progress, compatibility may break in future versions. If you are familiar with .NET's event manager classes you'll find this library easy to use!"
        link="https://github.com/ImanolFotia/beacon"
      />

      <ProjectCard
        name="Chip 8 Emulator"
        image="c1.png"
        languages="C++"
        description="Chip-8 emulator written in C++14. High compatibility with games found online.
This emulator was written in 4 days following various specifications online."
        link="https://github.com/ImanolFotia/Chip-8-Emulator"
      />

      <ProjectCard
        name="Epsilon OS"
        languages="C++ - x86 Assembly"
        image="png-transparent-command-line-interface-linux-command-line-interpreter-unix-linux-angle-rectangle-computer-program.png"
        description="Epsilon OS is an experimental OS made from scratch that aims to a be a lightweight microkernel for the x86 architecture Only IO, interrupts, and threading will be implemented in kernel-mode, All other components, such a Virtual File Systems will be implemented in user space."
        link="https://github.com/ImanolFotia/Epsilon-OS"
      />

      <ProjectCard
        name="This site"
        image="profile.jpeg"
        languages="Vue - ES6"
        description="Source code for this site"
        link="https://github.com/ImanolFotia/imanolfotia.github.io"
      />

      <router-link to="/projects/MdEditor">
        <a class="section">Markdown Editor</a>
      </router-link>
    </div>
  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import ProjectCard from "../components/ProjectCard.vue";

export default {
  components: {
    Nav,
    ProjectCard,
  },
};
</script>

<style scoped>
.divider {
  font-size: 1.5rem;
  color: #ffaa04;
}

.main {
  padding-left: 15%;
  padding-right: 15%;
}

.cards-container {
  width: 75%;
}


@media screen and (max-width: 1366px) {
  .main {
    /*
  max-width: 50%;
  margin-left: 25%;*/
    padding-left: 3%;
    padding-right: 3%;
  }

  .cards-container {
    width: 100%;
  }
}
</style>