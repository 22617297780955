<template>
  <nav class="level is-mobile is-gapless">
    <div class="level-item has-text-centered">
      <div>
        <router-link to="/"> <a class="heading section">home</a></router-link>
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <router-link to="/blog">
          <a class="heading section">blog</a></router-link
        >
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <router-link to="/projects">
          <a class="heading section">projects</a></router-link
        >
      </div>
    </div>
    <div class="level-item has-text-centered">
      <div>
        <router-link to="/demos">
          <a class="heading section">demos</a></router-link
        >
      </div>
    </div>
  <!--
    <div class="level-item has-text-centered">
      <div>
        <router-link to="/downloads">
          <a class="heading section">downloads</a></router-link
        >
      </div>
    </div>
-->
    <div class="level-item has-text-centered">
      <div>
        <router-link to="/about">
          <a class="heading section">about</a></router-link
        >
      </div>
    </div>
  </nav>
</template>

<script>
import "bulma/css/bulma.css";

export default {};
</script>

<style scoped>
.heading {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #ffaa04;
    margin: 0px;
}

@media screen and (max-width: 1000px) {
  
.heading {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #ffaa04;
    margin: 0px;
    padding-right: 0px;
    padding-left: 0px;
}
}
</style>