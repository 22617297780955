<template>
  <div class="home-div">
    <img alt="logo" src="../assets/profile.jpeg" id="profile-img" />
    <div class="main">
      <h1>{{ msg }}</h1>
      <div class="columns is-mobile is-gapless social-icons">
        <div class="column social-icons">
          <a class="github-link" href="https://github.com/ImanolFotia" target="_blank"
            ><font-awesome-icon
              class="github-icon"
              size="2x"
              title="Github"
              :icon="['fab', 'github']"
          /></a>
        </div>
        <div class="column social-icons">
          <a class="twitter-link" href="https://twitter.com/ImanolFotia" target="_blank"
            ><font-awesome-icon
              class="twitter-icon"
              size="2x"
              title="Twitter"
              :icon="['fab', 'twitter']"
          /></a>
        </div>
        <div class="column social-icons">
          <a class="instagram-link" href="https://instagram.com/imanolfotia/" target="_blank"
            ><font-awesome-icon
              class="instagram-background"
              size="2x"
              title="Instagram"
              :icon="['fab', 'instagram']"
          /></a>
        </div>
        <div class="column social-icons">
          <a
            class="youtube-link"
            href="https://youtube.com/channel/UC7H8LwfDXlPqQXTJbDIXgsw" target="_blank"
            ><font-awesome-icon
              class="youtube-icon"
              size="2x"
              title="Youtube"
              :icon="['fab', 'youtube']"
          /></a>
        </div>
        <div class="column social-icons">
          <a class="steam-link" href="https://steamcommunity.com/id/difima/" target="_blank"
            ><font-awesome-icon
              class="steam-icon"
              size="2x"
              title="Steam"
              :icon="['fab', 'steam']"
          /></a>
        </div>
        <div class="column social-icons">
          <a
            class="linkedin-link social-icons"
            href="https://www.linkedin.com/in/imanol-fotia-b09639b5/" target="_blank"
            ><font-awesome-icon
              class="linkedin-icon"
              size="2x"
              title="LinkedIn"
              :icon="['fab', 'linkedin']"
          /></a>
        </div>
      </div>
      <p>
        Hello, my name is Imanol Fotia and this is my portfolio website. In this
        website you will find my past, current and future projects. One of my
        biggest passions of my life are videogames, both playing and making
        them, and I started the latter right after I began my Software
        Engineering carrer, of which I graduated in 2016.
      </p>

      <p>
        Currently, my biggest project is the Epsilon Engine, be sure to check it
        out!
      </p>

      <p>
        But not only making the games are my objectives, I have spent the last
        years of my life researching and learning Graphics Programming and that
        has lead me to make my own engine.
      </p>

      <p>
        I'm looking forward to venture in this amazing industry of entertaiment
        as a game developer.
      </p>
      <div class="divider">Sections</div>
      <ul>
        <li>
          <router-link to="/"><a class="section">home</a> </router-link>
        </li>
        <li>
          <router-link to="/projects">
            <a class="section">projects</a>
          </router-link>
        </li>
        <li>
          <router-link to="/blog">
            <a class="section">posts</a>
          </router-link>
        </li>
        <li>
          <router-link to="/demos">
            <a class="section">demos</a>
          </router-link>
        </li>
        
  <!--
        <li>
          <router-link to="/downloads">
            <a class="section">downloads</a>
          </router-link>
        </li>
        -->
        <li>
          <router-link to="/about"> <a class="section">about</a></router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import "bulma";
import "@creativebulma/bulma-divider/dist/bulma-divider.css";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

library.add(fas);
library.add(fab);

export default {
  name: "Main",
  props: {
    msg: String,
  },
  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main {
  /*
  max-width: 50%;
  margin-left: 25%;*/
  padding-left: 25%;
  padding-right: 25%;
}

.home-div {
  max-height: 70px;
}

.section {
  font-size: 20px;
  padding: 0px;
  color: #ffaa04;
}

h1 {
  margin: 40px 0 0 !important;
  font-size: 2em;
  font-weight: bold;
  padding-bottom: 25px;
}
h2 {
  margin: 40px 0 0 !important;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 25px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}

a {
  padding: 20px;
}

a.github-link {
  color: lightgray;
}
a.youtube-link {
  color: red;
  padding-inline: 2px;
}
a.twitter-link {
  color: #1da1f2;
}
a.linkedin-link {
  color: rgb(40, 103, 178);
}
a.steam-link {
  color: lightgray;
}
.github-icon {
  padding-inline: 1px;
}
.youtube-icon {
  padding-inline: 1px;
}
.twitter-icon {
  padding-inline: 1px;
}
.linkedin-icon {
  padding-inline: 1px;
}
.steam-icon {
  padding-inline: 1px;
}
.instagram-background {
  padding-inline: 1px;
  background: radial-gradient(
      circle farthest-corner at 35% 90%,
      #fec564,
      transparent 50%
    ),
    radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
    radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 20% -50%,
      #5258cf,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
    radial-gradient(
      ellipse farthest-corner at 60% -20%,
      #893dc2,
      transparent 50%
    ),
    radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),
    linear-gradient(
      #6559ca,
      #bc318f 30%,
      #e33f5f 50%,
      #f77638 70%,
      #fec66d 100%
    );
  border-radius: 25%;
  color: white;
}

.social-icon {
  width: 35px;
}

@media screen and (max-width: 1000px) {
  .main {
    /*
  max-width: 50%;
  margin-left: 25%;*/
    padding-left: 3%;
    padding-right: 3%;
  }
}

@media screen and (max-width: 488px) {
  .social-icons {
    width: 64px !important;
    min-width: 64px !important;
    max-width: 64px !important;
    padding: 0px;
    margin: 0px;
  }
}
/*
@media screen and (max-width: 599px) {
  .social-icons {
    width: 100%;
  }
}*/

.divider {
  font-size: 1.25rem;
  color: #ffaa04;
}
</style>
