<template scoped>
  <Nav />
  <div class="main">
    <div class="divider">Blog</div>
    <div class="container.is-widescreen">
      <div class="columns">
        <div class="column is-one-quarter">
          <aside class="menu">
            <p class="menu-label">Entries</p>
            <div v-for="(year, index) in blogs" :key="index">
              <ul class="menu-list">
                <li>
                  <a class="year-label">{{ year }}</a>
                  <ul>
                    <li
                      v-for="(title, innerIndex) in posts(year)"
                      :key="innerIndex"
                    >
                      <router-link :to="'/blog/' + CurrentEntryId">
                        <a
                          v-on:click="showEntry(getId(year), innerIndex)">>{{ title }}</a
                        >
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </aside>
        </div>
        <div class="column is-three-quarters has-text-light">
          <BlogEntry
            :key="$route.fullPath"
            :title="CurrentEntry.Name"
            :date="CurrentEntry.Date"
            :filename="CurrentEntry.Filename"
            :id="CurrentEntry.Id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bulma/css/bulma.css";
import Nav from "../components/Nav.vue";
import BlogEntry from "../components/BlogEntry.vue";
import blog_entries from "../statics/Blogs.json";

export default {
  data() {
    return {
      BlogEntries: blog_entries,
      ProcessedEntries: [],
      CurrentEntryId: 0,
      CurrentEntry: {
        Name: "",
        Filename: "2016-12-28-helloworld.md",
        Date: "",
        Id: 0,
      },
    };
  },
  mounted: function () {
    let param = undefined;
    if (this.$route.redirectedFrom !== undefined)
      param = Number(this.$route.redirectedFrom.params.catchAll[0]);
    console.log(param);
    this.onLoad(param);
  },
  components: {
    Nav,
    BlogEntry,
  },
  computed: {
    blogs() {
      let blogs = new Set();
      this.BlogEntries.blogs.forEach((post) => blogs.add(post.year));
      console.log(this.BlogEntries.blogs[0].id);
      return this.changeData(blogs);
    },
  },
  methods: {

    onLoad(param) {
      if (param === undefined) {
        this.CurrentEntryId = blog_entries.blogs.length - 1;

        this.addHashToLocation(this.CurrentEntryId);

        //this.disqus_config.page.identifier = this.CurrentEntryId;
      } else {
        this.CurrentEntryId = param;

        //this.disqus_config.page.identifier = this.CurrentEntryId;

        this.addHashToLocation(param);
      }
      console.log(blog_entries.blogs.length);
      this.CurrentEntry.Filename =
        this.BlogEntries.blogs[this.CurrentEntryId].filename;

      this.CurrentEntry.Filename =
        this.BlogEntries.blogs[this.CurrentEntryId].filename;
      this.CurrentEntry.Name =
        this.BlogEntries.blogs[this.CurrentEntryId].title;
      this.CurrentEntry.Date = this.BlogEntries.blogs[this.CurrentEntryId].date;
      this.CurrentEntry.Id = this.CurrentEntryId;

      console.log(this.CurrentEntry);
    },
    changeData(blogs) {
      this.ProcessedEntries = Array.from(blogs).sort().reverse();
      return this.ProcessedEntries;
    },

    posts(year) {
      return this.BlogEntries.blogs
        .filter((post) => post.year === year)
        .map((post) => post.title);
    },

    getId(year) {
      return this.BlogEntries.blogs
        .filter((post) => post.year === year)
        .map((post) => post.id);
    },

    showEntry(id, index) {
      if (id === undefined) return;
      //this.lastSeleceted = 
      console.log(this.BlogEntries.blogs[id[index]].title);
      this.CurrentEntry.Filename = this.BlogEntries.blogs[id[index]].filename;
      this.CurrentEntry.Name = this.BlogEntries.blogs[id[index]].title;
      this.CurrentEntry.Date = this.BlogEntries.blogs[id[index]].date;
      this.CurrentEntry.Id = this.CurrentEntryId;
      this.addHashToLocation(this.BlogEntries.blogs[id[index]].id);
    },

    addHashToLocation(params) {
      history.pushState(
        {},
        null,
        this.$route.path + "/" + encodeURIComponent(params)
      );

      var resetDisqusPlugin = function () {
        if (typeof self.DISQUS === "undefined") {
          setTimeout(function () {
            resetDisqusPlugin();
          }, 3000);
        } else {
          try {
            self.DISQUS.reset({
              reload: true,
            });
          } catch (e) {
            console.error(e);
          }
        }
      };

      resetDisqusPlugin();
    },
  },
};
</script>

<style scoped>
.divider {
  font-size: 1.5rem;
  color: #ffaa04;
}

.main {
  padding-left: 3%;
  padding-right: 3%;
}

.menu {
  text-align: left;
}

.menu-list a {
  color: white;
}

.year-label {
  color: #ffaa04 !important;
}

@media screen and (max-width: 1366px) {
  .main {
    /*
  max-width: 50%;
  margin-left: 25%;*/
    padding-left: 3%;
    padding-right: 3%;
  }
}

.menu-list a:hover {
  background-color: #ffaa04;
  color: black;
}

.selected {
  background-color: whitesmoke;
  color: black !important;
}

.content h1 {
  color: white !important;
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  color: white !important;
}
</style>